// Table

.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th {
    padding: 8px;
    line-height: 1.4;
    vertical-align: top;
    border-top: 1px solid #ddd;
}

.table>tbody>tr>td>button.btn-link {
    padding-top: 0;
    padding-bottom: 0;
}

.no-items {
    text-align: center;
    width: 100%;
    float: left;
    margin-top: 1em;

    i {
        font-size: 60pt;
        margin-bottom: 10px;
        color: #9a9da0;
    }
}

.item-icons {
    .fa-image {
        margin-left: -21px;
        margin-right: 5px;
    }

    .fa-star {
        margin-left: 5px;
    }
}

td.item-icons {
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-create {
    float: right;
    color: white !important;
}

.form-delete {
    float: left;
}

.warning.btn, .warning.btn i {
    color: #ffbb00;
}

.danger.btn, .danger.btn i {
    color: #bb0000;
}

.success.btn, .success.btn i {
    color: #01c72c;
}

.copy-link {
    display: flex;
    flex-direction: row;
    width:100%;
    max-width: 1200px;
    margin: auto;

    > div {
        flex-grow: 1;
    }

    > button {
        
    }
}