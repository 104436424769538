.file-uploader {
    clear: both;
    width: 100%;
    padding: 20px 10px;
    background-color: #eee;
    border: 1pt dashed #ced4da;
    text-align: center;
    font-size: 10pt;
}

.file-uploader .animated-preloader {
    margin-bottom: 25px;
}

.file-uploader input[type=file] {
    display: none;
}

.file-uploader p {
    text-align: center;
    cursor: default;
    margin: 0;
    color: #333;
}
