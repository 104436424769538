$company-color: #c6323a;
$enoteca-color: #ffce00;
$preloaderInnerColor: $company-color;
$avatar-size: 160px;
$mobile-avatar-size: 80px;

.rafalauk-background-color {
    background-color: $company-color;
}

.enoteca-background-color {
    background-color: $enoteca-color;
}

.logo-img {
    width: 96px !important;
}

.iq-dashboard {
    .store.widget {
        display: flex;
        flex-wrap: wrap;
        box-shadow: none;
        background-color: transparent;
        width: 100%;

        .left {
            display: flex;
            justify-content: center;
            align-items: center;
            width: $avatar-size + 32;
            height: $avatar-size + 32;

            .avatar {
                max-width: $avatar-size !important;
                width: $avatar-size !important;
                height: $avatar-size !important;
            }
        }

        .right {
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            justify-content: center;
            align-items: start;
            padding-left: 25px;

            h1 {
                font-size: 3em;
                margin-bottom: 0.1em;
            }

            h2 {
                font-size: 1em;
                text-transform: uppercase;
            }

            h3 {
                font-size: 1em;
                margin-top: 0em;
            }

            .qr-link  {
                margin-top: 0.5em;
                margin-right: 0.5em;
                width: 100%;
                max-width: 700px;
            }
        }

        > .qr-link  {
            display: none;
        }

        .edit-store {
            position: absolute;
            top: calc(0.9em);
            right: calc(0.9em + 10px);
        }
    }
}


@media only screen and (max-width: $bootstrap-responsive-width) {
    .iq-dashboard {
        .store.widget {
            font-size: 11px;

            .left {
                display: flex;
                justify-content: center;
                align-items: center;
                width: $mobile-avatar-size + 10;
                height: $mobile-avatar-size + 10;
    
                .avatar {
                    max-width: $mobile-avatar-size !important;
                    width: $mobile-avatar-size !important;
                    height: $mobile-avatar-size !important;
                }
            }

            .right {
                .qr-link  {
                    display: none;
                }

                h1 {
                    font-size: 1.8em;
                }
            }

            > .qr-link  {
                display: flex;
                flex-basis: 100%;
            }
        }
    }
}

.home {
    .box {
        float: left;
        margin-bottom: 15px;
        width: 100%;
        height: 400px;
        color: white;
        font-weight: bold;
        border-radius: 3px;
        text-align: center;
        cursor: pointer;
        margin-top: 10px;
        background-size: cover;
        max-height: 256px;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
            font-size: 50pt;
            color: white;
            text-shadow: 2px 2px 3px #555;
        }
        
        h1 {
            color: white;
            font-family: Poppins, Helvetica, "sans-serif";
            font-weight: bold;
            text-shadow: 2px 3px 5px #222;
        }
    }
}

.home .box.shop-items {
    background-color: #67e454;
    background-image: url('./images/rafala/shop-bg.jpg');
    background-position: center center;
}

.home .box.enoteca {
    background-color: #ff2058;
    background-image: url('./images/rafala/enoteca-bg.jpg');
}

@media only screen and (max-width: $bootstrap-md-breakdown) {
    .home .box {
        width: 100%;
        height: 200px;
    }

    .home .box h1 {
        font-size: 16pt;
        margin-top: 0px;
    }

    .home .box .centered {
        margin-top: 30px;
    }
}

h2.paid-label {
    text-align: center;
    font-weight: bold;
    border: 1pt solid $company-color;
    padding-top: 5px;
    cursor: default;
    width: 100%;
    max-width: 200px;
    float: right;
}

.supply-stock-form .form-group {
    margin: auto;
}

.supply-stock-form {
    text-align: center;
}

table.stock button {
    margin: 2px;
}

.history .modal-dialog {
    max-width: 1000px;
}

.history-modal {
    padding: 15px;
}

.toggle-button.square {
    border-radius: 3px !important;
    width: auto !important;
    padding-left: 0.5em;
    padding-right: 0.5em;
}

// Store Edit Page
.your-store {
    .profile {
        h1 {
            text-align: center;
            margin-bottom: 1em;
        }

        p {
            text-align: center;
        }
        
        .form-image-options {
            text-align: center;
            margin-bottom: 1em;
        }

        .cover {
            margin-bottom: 1em;
            text-align: center;
            max-height: 300px;
        }

        .avatar {
            margin: auto;
            margin-bottom: 1em;
        }

        .qr-link {
            margin: auto;
            max-width: 700px;

            > span {
                text-align: center;
            }
        }
    }
        
    .field {
        width: 100%;
    }

    button[type=submit], .quill {
        margin-top: 1em;
        margin-bottom: 1em;
    }

    .tabs {
        margin-top: 1em;
    }

    .connect-with-stripe {
        position: relative;
        text-align: center;
        padding-bottom: 1em;
    }
    
    .connect-with-stripe-button {
        width: 14em;
        border-radius: 5px;
        margin: auto;
        float: none;
    }
    
    .connect-with-stripe-button i {
        margin-right: 0.3em;
    }

    .stripe-error-alert-container {
        padding: 1em;

        .stripe-error-alert {
            justify-content: center;
        }
    }
}

.order {
    .collection-shop-label {
        margin-left: 5px;
        padding: 3px 5px;
        border-radius: 4px;
        font-weight: bold;
        cursor: default;
        color: white;
        // color: $enoteca-color;
    }

    .collection-shop-label.enoteca {
        color: $company-color; // Rafala Color
    }
}