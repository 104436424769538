$width: 256px;
$height: 256px;
$padding: 15px;

.media-stock-image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: $width + $padding * 2;
    min-height: $height + $padding * 2;
    background-color: #f1f1f1;
    user-select: none;
    margin: auto;

    .image {
        width: $width;
        height: $height;
        background-size: cover;
        background-position: center center;
    }

    .overlay-texts {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
            font-weight: 500;
        }
    }
    
    input[type=file] {
        display: none;
    }

    .browse {
        display: none;

        i {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            color: #1e1e2d;
            font-size: 60px;
        }
    }

    .options {
        display: none;
        position: absolute;
        bottom: 5px;
        right: 5px;
        color: white;

        svg {
            color: #1e1e2d;
        }

        svg.delete-button {
            color: #ee1e2d;
        }
    }
}

.media-stock-image:hover {
    .options {
        display: block;
    }

    .browse {
        display: block;
    }
}

.media-stock-image.loading {
    .options {
        display: none;
    }

    .browse {
        display: none;
    }
}

.media-stock-image.drag-over {
    background-color: $primary-color;
}

.media-stock-image.rounded {
    .image {
        border-radius: 50%;
    }
}

.media-stock-image.squircle {
    .image {
        border-radius: 0.5em;
    }
}

.media-stock-image.cover {
    .image {
        border-radius: 0.5em;
        background-size: contain;
        background-repeat: no-repeat;
    }
}

.modal-body {
    img {
        width: 100%;
    }

    .image {
        // width: $width;
        // height: $height;
        height: auto;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
    }
}

@media only screen and (max-width: $bootstrap-responsive-width) {
    .options {
        display: block !important;
    }
}