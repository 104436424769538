.warning.btn, .warning.btn i {
    color: #ffbb00;
}

.danger.btn, .danger.btn i {
    color: #bb0000;
}

.success.btn, .success.btn i {
    color: #01c72c;
}

.success-button {
    color: #ffffff !important;
    background-color: #37cc81 !important;
}

.success-button:hover {
    background-color: #45e796 !important;
}

.danger-button {
    color: #ffffff !important;
    background-color: #F64E60 !important;
}

.danger-button:hover {
    background-color: #cc0000 !important;
}

.danger-link-button {
    color: #cc0000 !important;
    // background-color: #F64E60 !important;
}

.danger-link-button:hover {
    color: #fff !important;
    background-color: #cc0000 !important;
}