.home {
    .widget {
        background-color: white;
        border-radius: 3pt;
        margin-bottom: 15px;
        border: 1pt solid #dadada;
        box-shadow: 4px 4px 15px #ddd;
        overflow-x: hidden;
        overflow-y: auto;
        position: relative;
        padding: .5em;
        font-size: 13px;
        text-align: center;
    
        .actions {
            position: absolute;
            top: 0px;
            
            > * {
                float: left;
                height: 2em;
                width: 2em;
                margin-top: 0.6em;
                cursor: pointer;
            }
                    
            .sizer {
                width: 20px;
                height: 20px;
            }
        }
    
        .widget-actions {
            position: absolute;
            top: 0px;
            top: 0.3em;
            right: 0.6em;
            
            > * {
                float: left;
                height: 2em;
                width: 2em;
                margin-top: 0.6em;
                cursor: pointer;
            }
        }
    
        h1 {
            text-align: center;
            font-size: 1.8em;
            margin-top: 6px;
            color: $primary-color;
            font-weight: 500;
        }
        
        .widget-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255,255,255, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: default;
            z-index: 1;
        }
    }
    
    .widget.maximized {
        max-height: fit-content;
        min-height: 60%;
    }
    
    .widget.collapsed {
        height: 40px;
        overflow: hidden;
    
        h1 {
            font-size: 1.4em;
            margin-top: 2px;
        }
    
        .actions {
            top: 0;
        }
    }
    
    // .dashboard .asset-viewer.widget {
    //     margin-bottom: 15px;
    // }
    
    @media only screen and (max-width: $bootstrap-responsive-width) {
        .widget {
            max-height: 5000px !important;
    
            h1 {
                font-size: 16pt;
            }
        }
    }
    
    // .myaccount.widget .form-image {
    //     width: 100%;
    // }
    
    .connect-with-stripe {
        position: relative;
    }
    
    .connect-with-stripe-button {
        width: 14em;
        border-radius: 5px;
        margin: auto;
        float: none;
    }
    
    .connect-with-stripe-button i {
        margin-right: 0.3em;
    }
    
    .stripe-error-alert {
        justify-content: center;
    }
    
}

// * NEW DASHBOARD *

#kt_wrapper {
    // background-color: #f7f8f9;
}

.iq-dashboard {
    .widget {
        border-radius: 15px;
        padding: 0;
        margin-bottom: 2rem;
        box-shadow: #ccc 0rem 1.25rem 1.7rem 0rem;
        border: 1pt solid #f5f5f5;
        background-color: white;
        
        .events {
            display: flex;
            overflow-x: auto;
            overflow-y: hidden;
            font-size: 9pt;

            .card {
                cursor: pointer;
                flex-shrink: 0;
                flex-basis: 200px;
                height: 200px;
                margin: 5px;

                > div {
                    width: 100%;
                    height: 100%;

                    h2 {
                        margin-top: 15px;
                        margin-bottom: 15px;
                        font-size: 1.2em;
                    }

                    .recap {
                        font-size: 0.95em;
                        margin-top: 1.8em;
                    }
                }
            }

            .add-event {
                > div:hover {
                    background-color: #defffe;
                }

                > div {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                        
                    h2 {
                        margin-top: 0;
                        margin-bottom: 0;
                        font-family: "Poppins";
                        font-size: 1.1em;
                        text-transform: uppercase;
                        font-weight: bold;
                        letter-spacing: 1px;
                        color: #444;
                        margin-bottom: 10px;
                    }

                    .material-icons {
                        font-size: 5rem;
                        color: #dedede;
                    }
                }
            }
        }
    }
}