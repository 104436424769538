$miniQrSize: 26px;

.qr-link {
    display: flex;
    background-color: #eaeaea;
    border: 1pt solid #ccc;
    border-radius: 20px;
    padding: 5px 20px;
    cursor: pointer;
    align-items: center;

    > span {
        flex-grow: 1;
    }

    .qr-container {
        flex-basis: $miniQrSize;
        height: $miniQrSize;
        margin-left: 15px;
    }

    canvas {
        max-width: $miniQrSize;
        max-height: $miniQrSize;
    }
}

.qr-link:hover {
    > span {
        color: #0089fe;
    }
}

.qr-link-modal {
    .modal-dialog {
        margin-left: auto;
        margin-right: auto;
        max-width: 230px;

        .qr-container {
            display: flex;
            justify-content: center;
        }

        p {
            margin-top: 1.5em;
            text-align: center;
        }
    }
}