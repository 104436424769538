.widget {
    box-shadow: 2px 2px 5px #ccc;
    border: 1pt solid #ddd;
}

.admin-accountancy-color {
    color: #008170 !important;
}

.money-in-icon {
    color: #00A520 !important;
}

.money-out-icon {
    color: #aa0020 !important;
}

.tabs {
    .tab {
        min-height: 170px;
    }
}

.filepond--wrapper {
    float: left;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;

    input {
        display: none;
    }

    // Remove file temporarily disabled
    .filepond--action-remove-item {
        // display: none;
    }

    .filepond--credits {
        display: none;
    }
}