.sidenav {
    position: fixed;
    height: 100%;
    padding-top: 60px;
    width: $sidenav-width;
    background-color: #fafafa;
    overflow-y: auto;
    z-index: 1;
}

.nav-link {
    margin-right: 1px;
}

.nav-link i {
    font-size: 16pt;
    margin-right: 18px;
    width: 30px;
    color: $bootstrap-dark;
    // margin-left: 5px;
    text-align: center;
}

.nav i.fa-chevron-up, .nav i.fa-chevron-down {
    float: right;
    margin-top: 3px;
    margin-right: 0px;
}

.sidenav a.nav-link:hover {
    background-color: #eee;
}

.sidenav .active.nav-link {
    background-color: #eee;
    border-right: 3pt solid $primary-color;
    color: #343a40;
    font-weight: bold;
}

// Mobile Version

@media only screen and (max-width: $bootstrap-md-breakdown) {
    .nav-link {
        padding-left: 1px;
    }

    .nav-link i {
        font-size: 14pt;
        margin-right: 6px;
    }
}