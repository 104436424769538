// FormImage v 1.0

.form-image {
    background: #f5f5f5;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    position: relative;
    display: flex;
}

.rounded .form-image {
    overflow: hidden;
    border-radius: 50%;
}

.form-image .placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100px;
    font-size: 72pt;
    text-align: center;
}

.form-image .animated-preloader {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
}

.form-image.disabled {
    opacity: 0.5;
}

.form-image .disabled-message {
    display: none;
}

.disabled .form-image .disabled-message {
    display: block;
    text-align: center;
    padding: 10px;
}

.form-image input[type=file] {
    display: none;
}

.form-image .browse-avatar {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 72px;
    width: 100%;
    height: 100%;
}

.form-image .browse-avatar i {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: $primary-color;
}

.form-image:hover .browse-avatar {
    display: block;
}

.loading .form-image .browse-avatar, .disabled .form-image .browse-avatar, .view-only .form-image .browse-avatar {
    display: none !important;
}

.view-only .form-image-options, .disabled .form-image-options {
    display: none !important;
}