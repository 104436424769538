.table {
    > thead > tr > th {
        font-size: 0.85em;
        font-weight: bold;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        color: #555;
    }

    > tbody > tr > td {
        font-size: 1.2em;
    }

    i {
        font-size: 20px;
    }

    >tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th {
        padding: 8px;
        line-height: 1.4;
        vertical-align: top;
        border-top: 1px solid #ddd;
    }

    >tbody>tr>td>button.btn-link {
        padding-top: 0;
        padding-bottom: 0;
    }

    td button {
        white-space: nowrap;
        margin-top: -5px;
        margin-bottom: -4px;
    }

    .flex-end {
        display: flex;
        justify-content: end;
    }

    .record-actions {
        display: flex;
        flex-direction: row-reverse;
    }

    .td-v-center {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        min-height: 42px;
    
        .row-thumb {
            margin-right: 8px;
            overflow: hidden;
        }
    }
    
    .row-thumb {
        width: 42px;
        height: 42px;
        display: flex;
        justify-content: center;
        
        img {
            height: 100%;
        }
    }
}

table.editable tr {
    cursor: pointer;
}

table.sortable {
    tr {
        user-select: none;
        touch-action: none;
        box-sizing: border-box;
        position: relative;
        z-index: 0;
        transition: transform 0ms ease 0s;
    }

    tr .react-contextmenu-wrapper {
        position: absolute;
        width: 100%;
        background: rgba(255,0,0,0.2);
        left: 0;
        right: 0;
        height: 2.2em;
        text-align: center;
    }
}

.table-options {
    display: flex;
    width: 100%;
    justify-content: right;
}