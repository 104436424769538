﻿// Modal v1.4

.modal {
    z-index: 1100 !important; // override 1050
}

.input-modal  {
    .MuiTextField-root {
        width: 100%;
    }
}

.modal-backdrop {
    z-index: 1100 !important;
}

header.modal-title h1 {
    font-size: 1.5em;
    color: $primary-color;
    margin-bottom: 1.2em;
}

header.modal-title i {
    color: $primary-color;
    font-size: 1.4em;
    margin-top: 0.2em;
    margin-right: 0.4em;
    float: left;
}

.modal-overlay {
    position: absolute;
    display: flex;
    align-items: center;
    background: rgba(255,255,255,0.85);
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
}

.modal-overlay .animated-preloader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.modal-overlay .message {
    width: 100%;
    text-align: center;
}

.modal-overlay .icon {
    font-size: 3em;
    display: block;
    text-align:center;
    margin: 0.2em;
}

.react-responsive-modal-modal header {
    display: block;
    float: left;
    width: 100%;
    padding-bottom: 15px;
    margin-top: -9px;
}

.react-responsive-modal-modal header i {
    color: $primary-color;
    float: left;
    font-size: 16pt;
    margin-top: 7px;
    margin-right: 10px;
}

.react-responsive-modal-modal header h1 {
    color: $primary-color;
    font-size: 20pt;
    float: left;
    margin: 0;
}

.react-responsive-modal-modal button[type=submit] {
    margin: auto auto !important;
    display: block;
}

.modal .modal-header .close span {
    display: block;
}

@media only screen and (min-width: $res0) {
    .wide.modal .modal-dialog {
        max-width: 800px;
    }
}