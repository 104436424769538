.order .order-user {
    margin-bottom: 0;
}

.order .order-user .mail {
    margin-right: 10px;
}

.order .note {
    clear: both;
    width: 100%;
}

.order .type {
    clear: both;
    width: 100%;
    text-transform: uppercase;
}

.order .items .name {
    font-weight: bold;
}

.order .items .note {
    display:block;
    width: 100%;
    color: #444;
    font-style: italic;
}

.order .items .extras .extra {
    display: inline-block;
    border: 1pt solid $primary-color;
    margin: 5px;
    padding: 0 5px;
    border-radius: 15px;
}

.order .items .list-item {
    display: block;
    width: 100%;

    .quantity {
        float: left;
        width: 40px;
        text-align: center;
    }

    .name {
        float: left;
    }

    .x {
        float: left;
        width: 40px;
        text-align: center;
    }

    ul {
        clear:both;
        width: 100%;
        float: left;
        margin-top: 0.5em;
    }

    .price {
        float: right;
    }

    .price input {
        width: 100px;
        text-align: center;
    }

    .price label {
        margin-right: 10px;
    }

    .note {
        width: 100%;
        clear: both;
        margin-left: 10px;
    }

    .extras {
        width: 100%;
        clear: both;
    }
}

.order .total {
    float: right;
    font-weight: bold;
    width: 98px;
    text-align: center;
}

.shop-order-widget {
    text-align: center;
    min-height: 150px;
}

.shop-order-widget .far {
    text-align: center;
    margin-top: 15px;
    font-size: 40pt;
    color: #d2d2d2;
}

.shop-order-widget p {
    text-align: center;
    margin-top: 15px;
}